// import React from 'react';
import React from "react";
import CryptoJS from 'crypto-js';
import Loader from "react-js-loader";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';



export const encryptDataNew = (text) => {
  try {
    const key_hex = process.env.REACT_APP_KEY;
    const iv_hex = process.env.REACT_APP_IV;

    const key = CryptoJS.enc.Hex.parse(key_hex);
    const iv = CryptoJS.enc.Hex.parse(iv_hex);

    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
} catch (error) {
    console.error("encryptData Exception = ", error);
    return false;
}
};

export const decryptData = (encryptedText) => {
  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const decruptCipherJson = (ciphertext) => {
  try{
    var key = 'a1b2c3d4e5f6g7h8';
    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

    if(!decryptedMessage){
      // window.location.href = '/logout';
    }
  }catch{
    // window.location.href = '/logout';
  }
  
  return JSON.parse(decryptedMessage);
}

export const encryptCipherJson = (data) => {
  const key = 'a1b2c3d4e5f6g7h8';
  const keyWA = CryptoJS.enc.Utf8.parse(key);
  const ivWA = CryptoJS.lib.WordArray.random(16);
  const jsonString = JSON.stringify(data);
  const encryptedMessage = CryptoJS.AES.encrypt(jsonString, keyWA, { iv: ivWA });
  const hmacWA = CryptoJS.HmacSHA256(encryptedMessage.ciphertext, keyWA);
  const combinedWA = ivWA.concat(hmacWA).concat(encryptedMessage.ciphertext);
  const combinedBase64 = CryptoJS.enc.Base64.stringify(combinedWA);

  return combinedBase64;
}

export const encryptCipherJsonReact = (ciphertext) => {

  var key = 'a1b2c3d4e5f6g7h8';
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(ciphertext), key).toString();
  return ciphertext;
}

export const decruptCipherJsonReact = (ciphertext) => {
  try{
    var key = 'a1b2c3d4e5f6g7h8';
    var bytes  = CryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }catch{
    window.location.href = '/logout';
  }
  return decryptedData;
}

export const decruptCipherString = (ciphertext) => {

  try{
    var key = 'a1b2c3d4e5f6g7h8';
  
    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);
    
  }catch{
    window.location.href = '/logout';
  }
  
  return decryptedMessage;
}

export const CustomFieldLevelLoader = () => {
  return (
    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f0j83/menjruksxj.gif" style={{width: '80px'}} alt="Loader..."></img>
  )
}

export const CustomLoader = () => {
  
  return (
      <div className="loading-overlay col-md-12">
          <div className="loader-position">
              <Loader type="spinner-default" bgColor={"#000"} color={'#FFFFFF'} size={100} />
          </div>
      </div>
  )

}

export const SelectProgram = ({ programData, selectedProgram, onProgramChange }) => {
  return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor='SelectProgram' className="col-form-label">Select Program</label>
      </div>
      <div className="col">
        <select 
          className="form-select input-field-ui" 
          id="programId" 
          name="programId" 
          value={selectedProgram} 
          onChange={onProgramChange}
          aria-label="Default select example"
        >
          <option>Select Role</option>
          {programData.map(option => (
            <option key={option.programId} value={option.programId}>
              {option.programName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const LoaderLoader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay col-md-12">
    <div className="loader-position">
        <Loader type="spinner-default" bgColor={"#000"} color={'#FFFFFF'} size={100} />
    </div>
</div>
  );
};


export const ModalBox = (props) =>{ 
  
  const showModal= props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen;
  const type = props.type;
  let cls = '';
  if(type==''  || typeof(type)=='undefined'){
    cls = (title==='Alert') ?'modal-sm': ((title==='Alert!') ? 'modal-md' : 'modal-xl');
  }else{
    cls = (type==='sm') ?'modal-sm': ((type==='md') ? 'modal-md' : 'modal-xl');
  }
 
  return (
      <>
        <Modal dialogClassName={cls} show={showModal} onHide={props.handleClose}>
          <Modal.Header closeButton>
          <Modal.Title 
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "700",  
              color: title === "Alert" ? "red" : "black" 
            }}
          >
            {title}
          </Modal.Title>
          </Modal.Header>
          <hr/>
          <Modal.Body >
            {modalbodyComponent}
          </Modal.Body>
        </Modal>
      </>
  );
  
}


export const ModalBoxButton = (props) =>{ 
  
  const showModal= props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen ;
  const type = props.type ;
  const buttonAction = props.button ;
  const hideButton = props.hideButton;
  
  let cls = '';
  if(type==''  || typeof(type)=='undefined'){
    cls = (title==='Alert') ?'modal-sm': ((title==='Alert!') ? 'modal-md' : 'modal-xl');
  }else{
    cls = (type==='sm') ?'modal-sm': ((type==='md') ? 'modal-md' : 'modal-xl');
  }
 
  return (
      <>
        <Modal dialogClassName={cls} show={showModal} className="modal-dialog-centered" centered id="MemberTransferModuleBox" onHide={props.handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={!!hideButton}>
          <Modal.Title 
            style={{
              width: "90%",
              color: title === "Alert" ? "red" : "black" 
            }}
          >
            {title}
          </Modal.Title>
          </Modal.Header>
          <hr/>
          <Modal.Body >
            <div className="row">
              <div className="col-md-12 text-center">
             <h3 className="main-subtitle "> {modalbodyComponent}</h3>
              </div>
              <div className="col-md-12 my-3 text-center">
              {buttonAction && <button className='btn program-save-btn me-2' onClick={buttonAction}>Ok</button>}
              </div>
            </div>
            
          </Modal.Body>
        </Modal>
      </>
  );
  
}


export const ModalBoxYesNo = (props) =>{ 
  
  const showModal= props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen ;
  const type = props.type ;
  const buttonYes = props.buttonYes;
  const buttonNo = props.buttonNo;


  let cls = '';
  if(type==''  || typeof(type)=='undefined'){
    cls = (title==='Alert') ?'modal-sm': ((title==='Alert!') ? 'modal-md' : 'modal-xl');
  }else{
    cls = (type==='sm') ?'modal-sm': ((type==='md') ? 'modal-md' : 'modal-xl');
  }
 
  return (
      <>
        <Modal dialogClassName={cls} id="commonModulePop" centered show={showModal} onHide={props.handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title 
            style={{
              // textAlign: "center",
              color: title === "Alert" ? "#FF0000" : "#404040" 
            }}
          >
            {title}
          </Modal.Title>
          </Modal.Header>
        
          <Modal.Body >
            <div className="row">
                <div className="col-12 mb-3">
                <p>{modalbodyComponent}</p>
                </div>
                <div className="col-12 text-center">
                {buttonYes && <button className='btn program-save-btn me-2' onClick={buttonYes}>Yes</button>}
                {buttonNo && <button className='btn program-save-btn' onClick={buttonNo}>No</button>}
                </div>
            </div>
            
            
          </Modal.Body>
        </Modal>
      </>
  );
  
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const Commingsoon = () =>{ 
  
  return(
          <div className="modal fade" id="upcomingFeatureModal" tabindex="1" aria-labelledby="upcomingFeatureModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                  <div className="modal-header mb-4">
                                      <h4 className="modal-subtitle">Available in Phase 2!</h4>
                            <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{height:"260px"}} alt="" />
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
  );

  };

  export const CommingsoonPage = () =>{ 
  
    return(
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header mb-4">
                    <h4 className="modal-subtitle">Available in Phase 2!</h4>
                    <h1 className="modal-title mt-3">Upcoming Feature:</h1>
                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{height:"260px"}} alt="" />
                  </div>
                </div>
            </div>
          );
    };


  export const PathwayDots=({pathway})=> {

 
    return (
        <>
            <div className='DropDownAction'>
            <div class="btn-group dropend">
            <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu">
                <li className='list-class'><Link to={`/add-service/${pathway}`} class="dropdown-item"><i class="bi bi-pen"></i> Edit Pathway</Link></li>
                {/* <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Pathway</Link></li> */}
                <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Pathway</Link></li>
            </ul>
            </div>
            </div>
            <Commingsoon />
        </>
      );
    }

    export const ProgramDots=()=> {

 
      return (
          <>
              <div className='DropDownAction'>
              <div class="btn-group dropend">
              <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
              </button>
              <ul class="dropdown-menu">
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal"><i class="bi bi-pen"></i> Edit Program</Link></li>
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Condition</Link></li>
                  <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Program</Link></li>
              </ul>
              </div>
              </div>
              <Commingsoon />
          </>
        );
      }


      
export const exportTableData = (csvName,modifiedData) => {
  try {
      const current = new Date();
      const date = `${current.getMonth() + 1}-${current.getDate()}-${current.getFullYear()},${current.getHours()}-${current.getMinutes()}-${current.getSeconds()}`;
      var wb = XLSX.utils.book_new();
  
      var ws = XLSX.utils.json_to_sheet(modifiedData);
  
      XLSX.utils.book_append_sheet(wb, ws, `${csvName}`);
  
      XLSX.writeFile(wb, `${csvName} ${date}.xlsx`);
  } catch (error) {
      console.error("Exception Error CSV Download = ", error);
      return false;
  }
};

export const exportExcelTableData = (excelName,modifiedData) => {
  try {
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}, ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
      var wb = XLSX.utils.book_new();
  
      var ws = XLSX.utils.json_to_sheet(modifiedData);
  
      XLSX.utils.book_append_sheet(wb, ws, `${excelName}`);
  
      XLSX.writeFile(wb, `${excelName} ${date}.xls`);
  } catch (error) {
      console.error("Exception Error XLS Download = ", error);
      return false;
  }
};


export const exportPdfTableData = async (pdfName,tableData,columns) => {
  try {
    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const pdf = new jsPDF();

    // Add table to PDF
    pdf.autoTable({
      head: [columns],
      body: tableData,
      theme: 'striped',
      startY: 10,
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
     
    });

    // Save the PDF with the name and timestamp
    pdf.save(`${pdfName} ${dateString}.pdf`);
    return true;
  } catch (error) {
    console.error("Exception Error PDF Download = ", error);
    return false;
  }
};